import React from "react";
import EBook1 from "./../images/Ebook1.png";

const Portfolio = () => {
  return (
    <>
      <div className="my-4 py-4" id="portfolio">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
          E-Books
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
              <div className="m-2 text-justify text-sm">
                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
                  Little Bear learns to help
                </h4>
                <img
                  src={EBook1}
                  alt="Little Bear learns to help"
                  className="w-full h-full object-cover rounded-lg mb-4"
                />
                <p className="text-md font-medium leading-5 h-auto md:h-50">
                  "Little Bear Learns to Help" is a heartwarming tale about
                  Benny, a little bear who discovers the magic of kindness and
                  friendship. Benny starts off as a grumpy, selfish cub who
                  never wants to help anyone. But when he finds himself in need,
                  his friends surprise him with their kindness, teaching him an
                  important lesson: helping others makes everyone’s heart feel
                  warm and happy. Perfect for little readers aged 3-5, this
                  delightful story is filled with gentle lessons about sharing,
                  caring, and the power of friendship. With lovable characters
                  and an inspiring message, it’s the perfect bedtime story to
                  spark conversations about kindness and compassion. Let your
                  little one join Benny on his journey to becoming a caring and
                  helpful friend!
                </p>
                <p className="text-lg font-semibold mt-4">Price: $2.50</p>
                <div className="flex justify-center my-4">
                  <a
                    href="/buy-ebook/#buy-ebook"
                    className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
                  >
                    Buy this e-Book
                    <svg
                      className="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="text-center mt-4">
                  <a
                    href="https://a.co/d/6eUrC5z"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-900 hover:underline text-lg font-medium"
                  >
                    Available on Amazon
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
